const msg = {
    transaction: {
        deposit: {
            summaryTxt: 'Deposit/Shield assets from Layer 1 (e.g. MetaMask wallet) to EigenSecret. Once shielded, the transaction will be encrypted on EigenSecret.',
            buttonTxt: 'Deposit to L2'
        },
        send: {
            summaryTxt: 'Send private assets to another EigenSecret Account with prefix eig. Please make sure the address is correct.',
            buttonTxt: 'Send to L2'
        },
        withdraw: {
            summaryTxt: 'Withdraw assets from EigenSecret to L1 via receiver\'s EigenSecret Address, account ID or EOA. Please make sure the address is correct.',
            buttonTxt: 'Withdraw to L1'
        }
    }
}
export default msg
