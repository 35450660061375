<template>
  <div class="main-container">
    <div class="main-item header" >
      <Header/>
    </div>
    <div class="transaction-detail-container">
      <div class="content-container">
        <div class="title">Transaction Details</div>
        <div class="item">
          <div class="prefix">amount</div>
          <div class="content" @click="copyContent(transactionDetail.amount)" title="Click to copy" >{{transactionDetail.amount}}</div>
        </div>
        <div class="item">
          <div class="prefix">assetId</div>
          <div class="content" @click="copyContent(transactionDetail.assetId)" title="Click to copy" >{{transactionDetail.assetId}}</div>
        </div>
        <div class="item">
          <div class="prefix">operation</div>
          <div class="content" @click="copyContent(transactionDetail.operation)" title="Click to copy" >{{transactionDetail.operation}}</div>
        </div>
        <div class="item">
          <div class="prefix">status</div>
          <div class="content" @click="copyContent(transactionDetail.status)" title="Click to copy" >{{transactionDetail.status}}</div>
        </div>
        <div class="item">
          <div class="prefix">symbol</div>
          <div class="content" @click="copyContent(transactionDetail.symbol)" title="Click to copy" >{{transactionDetail.symbol}}</div>
        </div>
        <div class="item">
          <div class="prefix">timestamp</div>
          <div class="content" @click="copyContent(transactionDetail.timestamp)" title="Click to copy" >{{transactionDetail.timestamp}}</div>
        </div>
        <div class="item">
          <div class="prefix">to</div>
          <div class="content" @click="copyContent(transactionDetail.toReal)" title="Click to copy" >{{transactionDetail.toReal}}</div>
        </div>
        <div class="item">
          <div class="prefix">txhash</div>
          <div class="content" @click="copyContent(transactionDetail.txhashReal)" title="Click to copy" >{{transactionDetail.txhashReal}}</div>
        </div>
        <div class="item">
          <div class="prefix">operation</div>
          <div class="content" @click="copyContent(transactionDetail.operation)" title="Click to copy" >{{transactionDetail.operation}}</div>
        </div>
        <div class="item">
          <div class="prefix">proof</div>
          <div class="content" @click="copyContent(transactionDetail.proof)" title="Click to copy" >{{transactionDetail.proof}}</div>
        </div>
        <div class="item">
          <div class="prefix">publicInput</div>
          <div class="content" @click="copyContent(transactionDetail.publicInput)" title="Click to copy" >{{transactionDetail.publicInput}}</div>
        </div>
      </div>
    </div>
    <div class="main-item footer">
      <Footer/>
    </div>
  </div>


</template>
<script>
import {getStatusTxt} from '@/utils/index';
import secretManager from '@/SecretManager/SecretManager';
import {getAlias, getSigner, getTransactionDetail} from "@/store";
import Header from "@/components/Header/index.vue";
import Footer from "@/components/Footer/index.vue";

export default {
  name: 'TransactionDetail',
  components: {Footer, Header},
  data() {
    return {
      getStatusTxt,
      page: 1,
      pageSize: 10,
      pageData: [],
      totalCount: 0,
      transactionDetail: {}
    }
  },
  methods: {
    copyContent(data) {
      navigator.clipboard.writeText(data)
    },
    initData() {
      this.transactionDetail = getTransactionDetail()
    }
  },
  created() {
    this.initData()
  },
  watch: {
    'page': function() {
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
@import 'index';
</style>
