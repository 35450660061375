<template>
  <div class="page-dashboard" ref="header">
    <div class="page-container">
      <Header/>
      <div class="nav-title">Net Worth</div>
      <div class="assets-nav-box">
        <div class="assets-nav-left">
          <p class="asset-total">${{totalBalanceUSD}}</p>
        </div>
        <div class="assets-nav-right">
          <button class="submit-btn" @click="transClick('Deposit')">Deposit</button>
          <button class="submit-btn" @click="transClick('Send')">Send</button>
          <button class="submit-btn" @click="transClick('Withdraw')">Withdraw</button>
        </div>
      </div>
      <Asset :asset-list="assetList"/>
      <div class="multi-transaction-box" ref="multiTransactionBox">
        <MultiTransactionTab ref="multiTransactionTab"/>
      </div>
      <div class="transaction-list-box">
        <div class="common-block-title">Transaction History</div>
        <Transaction/>
      </div>
    </div>
    <ConfirmDialog
        :dialogDes="dialogDes"
        :dialogType="dialogType"
        :dialogVisible.sync="dialogVisible"
    />
    <Footer @to-header="toHeader"/>
  </div>
</template>

<script>
import Header from '@/components/Header/index';
import Footer from '@/components/Footer/index';
import Transaction from './Transaction/index';
import Asset from './Asset/index';
import MultiTransactionTab from './MultiTransactionTab/index';
import ConfirmDialog from '@/components/ConfirmDialog/index';
import secretManager from '@/SecretManager/SecretManager';
import {getAlias, getSigner, setAssetList} from "@/store";

export default {
  name: 'dashboard-page',
  data() {
    return {
      assetList: [],
      totalBalanceUSD: 0,
      dialogDes: '',
      dialogType: '',
      dialogVisible: false,
    }
  },
  components: {
    Header,
    Footer,
    Transaction,
    ConfirmDialog,
    MultiTransactionTab,
    Asset
  },

  methods: {
    getAllBalance() {
      const options = {
        alias: getAlias(),
        password: secretManager.getPassword(),
        user: getSigner(),
        page: this.page,
        pageSize: this.pageSize
      }
      secretManager.getAllBalance(options).then((res) => {
        this.assetList = res?.data?.assetInfo?.map(item => {
          return {
            symbol: item.tokenInfo?.symbol,
            asset: item.assetId,
            balance: item.balance,
            value: item.balanceUSD,
            hour: item.profit24Hour,
            hourReturn: item.return
          }
        })
        this.totalBalanceUSD = res?.data?.totalBalanceUSD
        setAssetList(this.assetList)
        console.log('getAssets res2: ', this.assetList)
        console.log('getAssets res: ', res)
        // to convert
      }).catch((e) => {
        console.error('getAssets error: ', e)
      })
    },
    onTransaction() {
      this.$eventBus.$on('transaction-success', (data) => {
        this.getAllBalance()
      })
    },
    transClick(type) {
      this.$refs.multiTransactionTab.switchTab(type)
      this.scrollToSection()
    },
    scrollToSection() {
      const section = this.$refs.multiTransactionBox;
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    },
    toHeader() {
      const section = this.$refs.header;
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    }
  },

  created() {
    this.getAllBalance()
    this.onTransaction()
  },

};
</script>
<style lang="scss" scoped>
@import 'index.scss';
</style>

